@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Open Sans', sans-serif; }

.form-block {
  margin-top: 10px; }

.clickable {
  cursor: pointer; }

.form-inputs label {
  font-weight: bold;
  margin: 5px 0 0 0; }
.form-inputs h3 {
  margin-top: 20px; }

.screen-title {
  font-size: xx-large;
  font-weight: 800;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .screen-title .back-button {
    width: 40px;
    height: 40px;
    border: 2px solid #000;
    border-radius: 10px;
    cursor: pointer;
    background-position: center;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    margin-right: 20px; }
    .screen-title .back-button:hover {
      background-color: #EFEFEF; }
  .screen-title .title-text {
    display: block;
    flex: 1 1; }

.screen-actions {
  margin-top: 20px; }

.screen-search-icon {
  width: 1.2em;
  height: 1.2em;
  display: inline-block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; }

.btn-rounded {
  border-radius: 999px !important;
  padding: 10px 12px !important; }

.btn-fullwidth {
  width: 100%; }

.file-hide {
  width: 1px;
  height: 1px;
  display: block;
  overflow: hidden;
  left: -9999px;
  top: -9999px;
  position: fixed; }

.horizontal-check-list label {
  border: 1px solid #CCC;
  padding: 5px;
  display: flex;
  cursor: pointer;
  align-items: center;
  border-radius: 5px; }
  .horizontal-check-list label .text {
    flex: 1 1;
    margin-left: 10px;
    display: block; }
  .horizontal-check-list label:hover {
    background-color: #EFEFEF; }

/*# sourceMappingURL=App.css.map */

.login-component .login-box {
  width: 100%;
  border: 1px solid #EFEFEF;
  border-radius: 10px;
  padding: 20px;
  margin: 40px auto 40px auto; }
  .login-component .login-box .logo {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; }
  .login-component .login-box label {
    font-weight: bold;
    margin-bottom: 0; }
  .login-component .login-box .mode-title {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .login-component .login-box .mode-title .back-button {
      width: 30px;
      height: 30px;
      border: 1px solid #CCC;
      border-radius: 5px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
      transition: background-color 200ms;
      display: inline-block; }
      .login-component .login-box .mode-title .back-button:hover {
        background-color: #EFEFEF; }
    .login-component .login-box .mode-title .text {
      flex: 1 1;
      margin-left: 10px;
      font-weight: bold; }
  .login-component .login-box .nav-link {
    border: 1px solid #EEE; }

/*# sourceMappingURL=LoginComponent.css.map */

.alert-modal {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: default;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  z-index: 1000; }
  .alert-modal .spacer {
    flex: 1 1; }
  .alert-modal .alert-modal-dialog {
    width: 100%;
    background: #FFF;
    border-radius: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    text-align: center;
    padding: 20px; }
    .alert-modal .alert-modal-dialog .alert-modal-dialog-icon {
      width: 40px;
      height: 40px;
      margin: auto; }
    .alert-modal .alert-modal-dialog .alert-modal-dialog-title {
      font-size: large;
      font-weight: bold;
      display: block;
      margin-top: 10px; }
    .alert-modal .alert-modal-dialog .alert-modal-dialog-message {
      font-size: medium;
      margin-top: 10px;
      display: block; }
    .alert-modal .alert-modal-dialog .alert-modal-dialog-buttons {
      display: block;
      margin-top: 15px; }
      .alert-modal .alert-modal-dialog .alert-modal-dialog-buttons .alert-dialog-button {
        width: 100%;
        display: block;
        border-radius: 999px;
        background-color: #EFEFEF;
        color: #000;
        margin-top: 10px;
        cursor: pointer;
        transition: background-color;
        text-align: center;
        padding: 10px; }
        .alert-modal .alert-modal-dialog .alert-modal-dialog-buttons .alert-dialog-button:first-child {
          margin-top: 0; }
        .alert-modal .alert-modal-dialog .alert-modal-dialog-buttons .alert-dialog-button:hover {
          background-color: #DFDFDF; }
        .alert-modal .alert-modal-dialog .alert-modal-dialog-buttons .alert-dialog-button.success {
          background: #00CC00;
          color: #FFF; }
          .alert-modal .alert-modal-dialog .alert-modal-dialog-buttons .alert-dialog-button.success:hover {
            background-color: #00AA00; }
        .alert-modal .alert-modal-dialog .alert-modal-dialog-buttons .alert-dialog-button.danger {
          background: #CC0000;
          color: #FFF; }
          .alert-modal .alert-modal-dialog .alert-modal-dialog-buttons .alert-dialog-button.danger:hover {
            background-color: #AA0000; }

/*# sourceMappingURL=AlertModal.css.map */

.header-bar-component .header-bar {
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #000;
  color: #FFF;
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 999; }
  .header-bar-component .header-bar .logo {
    flex: 1 1;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
    display: block; }
  .header-bar-component .header-bar .actions {
    display: block; }
    .header-bar-component .header-bar .actions .action-item {
      width: 40px;
      height: 40px;
      border: 2px solid #FFF;
      display: inline-block;
      border-radius: 50%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-left: 10px;
      margin-top: 5px;
      cursor: pointer;
      position: relative; }
      .header-bar-component .header-bar .actions .action-item:hover {
        background-color: rgba(255, 255, 255, 0.2); }
      .header-bar-component .header-bar .actions .action-item .badge {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #CC0000;
        color: #FFF;
        font-size: small;
        margin-left: -5px;
        margin-top: -5px; }

.header-bar-padding {
  padding-top: 50px; }

/*# sourceMappingURL=HeaderBarComponent.css.map */

.site-info-bar-component {
  background: #333; }
  .site-info-bar-component .site-bar {
    width: 100%;
    color: #FFF;
    display: flex;
    padding: 5px 0;
    align-items: center; }
    .site-info-bar-component .site-bar .site-display {
      flex: 1 1;
      font-size: medium; }
    .site-info-bar-component .site-bar .site-button {
      display: inline-block;
      border: 1px solid #FFF;
      padding: 4px 10px;
      font-size: small;
      border-radius: 999px;
      color: #FFF;
      text-decoration: none;
      cursor: pointer; }
      .site-info-bar-component .site-bar .site-button:hover {
        background-color: rgba(255, 255, 255, 0.2); }

/*# sourceMappingURL=SiteInfoBarComponent.css.map */

.loading-spinner-component {
  display: block;
  text-align: center; }
  .loading-spinner-component.inline-block {
    display: inline-block; }
  .loading-spinner-component .loading-spinner {
    width: 40px;
    height: 40px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    animation: spin 2s linear infinite; }
    .loading-spinner-component .loading-spinner.large {
      width: 80px;
      height: 80px; }
@keyframes spin {
  80% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(180deg); } }

/*# sourceMappingURL=LoadingSpinner.css.map */

.ui-blocker-component {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column; }
  .ui-blocker-component.containable {
    position: absolute; }
  .ui-blocker-component .spacer {
    flex: 1 1; }

/*# sourceMappingURL=UIBlockerComponent.css.map */

.statistic-vertical {
  position: relative; }
  .statistic-vertical .statistic-title {
    text-align: center;
    display: block;
    font-weight: bold;
    margin-top: 10px; }
  .statistic-vertical .date-picker-container {
    position: relative; }
    .statistic-vertical .date-picker-container .date-display {
      text-align: center;
      padding: 10px;
      font-weight: bold;
      cursor: pointer;
      border: 1px solid #EFEFEF;
      border-radius: 5px;
      margin: 5px 10px;
      box-sizing: border-box;
      display: block; }
      .statistic-vertical .date-picker-container .date-display:hover {
        background-color: rgba(0, 0, 0, 0.05); }
    .statistic-vertical .date-picker-container .date-picker-popout {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      background: #FFF;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      z-index: 999;
      padding: 10px;
      margin: 0 10px; }
      .statistic-vertical .date-picker-container .date-picker-popout .date-picker-title {
        display: block;
        font-size: medium;
        font-weight: bold;
        text-align: center; }
      .statistic-vertical .date-picker-container .date-picker-popout label {
        font-size: small;
        display: block;
        margin-top: 10px;
        text-align: center; }
      .statistic-vertical .date-picker-container .date-picker-popout .react-datepicker-wrapper {
        width: 100%; }
        .statistic-vertical .date-picker-container .date-picker-popout .react-datepicker-wrapper .react-datepicker__input-container input[type=text] {
          width: 100%;
          text-align: center;
          border: 1px solid #EFEFEF;
          padding: 10px 5px; }
      .statistic-vertical .date-picker-container .date-picker-popout .fullwidth {
        width: 100%;
        margin-top: 10px; }
  .statistic-vertical .gauge-chart {
    position: relative; }
    .statistic-vertical .gauge-chart .overlay-display {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center; }
      .statistic-vertical .gauge-chart .overlay-display .title {
        font-size: 3.4em;
        font-weight: 800; }
      .statistic-vertical .gauge-chart .overlay-display .subtitle {
        width: 60%;
        margin-left: 20%;
        font-size: small; }
  .statistic-vertical .bar-chart {
    margin-top: 10px;
    margin-bottom: 10px; }

/*# sourceMappingURL=SiteConfigurationStatisticsVerticalGraphComponent.css.map */

.main-menu-component {
  padding-bottom: 40px; }
  .main-menu-component .item-margin {
    margin-top: 10px; }
  .main-menu-component .menu-item {
    position: relative;
    border-radius: 10px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; }
    .main-menu-component .menu-item .menu-item-content {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 10px; }
      .main-menu-component .menu-item .menu-item-content .badge-container {
        display: block; }
        .main-menu-component .menu-item .menu-item-content .badge-container .badge {
          background: #CC0000;
          color: #FFF; }
      .main-menu-component .menu-item .menu-item-content .item-title {
        font-size: x-large;
        font-weight: 800; }
    .main-menu-component .menu-item.alerts {
      background-color: #f389fc; }
    .main-menu-component .menu-item.audit {
      background-color: #89fcc5; }
    .main-menu-component .menu-item.specification {
      background-color: #fccd89; }
  .main-menu-component .graph-section {
    margin-top: 40px; }
    .main-menu-component .graph-section .graph-space {
      margin-bottom: 10px; }
  .main-menu-component .statistics-button {
    display: flex;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 999px;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
    margin-top: 40px; }
    .main-menu-component .statistics-button:hover {
      background: rgba(0, 0, 0, 0.1); }
    .main-menu-component .statistics-button .spacer {
      flex: 1 1; }
    .main-menu-component .statistics-button .icon {
      width: 25px;
      height: 25px;
      display: block;
      margin-left: 10px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat; }
    .main-menu-component .statistics-button .text {
      font-weight: bold;
      margin-left: 10px;
      margin-right: 10px; }
  .main-menu-component .estate-report-table {
    margin-top: 20px; }

/*# sourceMappingURL=MainMenuComponent.css.map */

.ep-table {
  width: 100%; }
  .ep-table thead {
    background: #000;
    color: white;
    font-weight: bold; }
    .ep-table thead th {
      padding: 5px;
      border: 1px solid #000; }
  .ep-table tbody tr {
    border: 1px solid #000; }
    .ep-table tbody tr:hover {
      background: rgba(0, 0, 0, 0.05); }
    .ep-table tbody tr td {
      border: 1px solid #000;
      padding: 5px; }
  .ep-table.clickable tbody tr {
    cursor: pointer; }
  .ep-table.alternating tbody tr:nth-child(even) {
    background: rgba(0, 0, 0, 0.03); }
    .ep-table.alternating tbody tr:nth-child(even):hover {
      background: rgba(0, 0, 0, 0.07); }
  .ep-table .sortable {
    cursor: pointer;
    background-position: right center;
    background-repeat: no-repeat;
    padding-right: 20px; }

.ep-table-paginate-container {
  display: flex;
  flex-direction: row; }
  .ep-table-paginate-container .push {
    flex: 1 1; }

.ep-table-paginate {
  display: flex;
  flex-direction: row;
  border: 1px solid #CCC;
  border-radius: 5px;
  overflow: hidden; }
  .ep-table-paginate .page-item, .ep-table-paginate .page-next, .ep-table-paginate .page-prev, .ep-table-paginate .page-gap {
    padding: 5px 10px;
    border-right: 1px solid #CCC;
    cursor: pointer; }
  .ep-table-paginate .page-item:hover {
    background: #EEE; }
  .ep-table-paginate .page-item.active {
    font-weight: bold; }
  .ep-table-paginate .page-gap {
    cursor: default; }
  .ep-table-paginate .page-next, .ep-table-paginate .page-prev {
    background: #EEE;
    color: #444; }
    .ep-table-paginate .page-next:hover, .ep-table-paginate .page-prev:hover {
      background: #DDD; }
  .ep-table-paginate .page-next {
    border: 0; }

/*# sourceMappingURL=TableComponent.css.map */

.toast-manager-component {
  width: 100%;
  max-width: 350px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  right: 0;
  top: 0;
  margin-top: 60px;
  margin-right: 10px; }
  .toast-manager-component .toast-item {
    width: 100%;
    display: flex;
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid #CCC;
    border-radius: 5px;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    animation-name: toast-animation;
    animation-duration: 3996ms;
    animation-iteration-count: 1;
    position: relative; }
    .toast-manager-component .toast-item .toast-icon {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-position: center;
      background-size: 25px 25px;
      background-repeat: no-repeat; }
    .toast-manager-component .toast-item .toast-content {
      flex: 1 1;
      display: block;
      margin-left: 10px; }
      .toast-manager-component .toast-item .toast-content .toast-content-title {
        font-weight: bold;
        display: block; }
      .toast-manager-component .toast-item .toast-content .toast-content-message {
        display: block; }
    .toast-manager-component .toast-item .toast-dismiss {
      width: 20px;
      height: 20px;
      background-color: #CC0000;
      border: 1px solid #CCC;
      border-radius: 50%;
      position: absolute;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
      right: 0;
      top: 0;
      margin: 5px;
      opacity: 0;
      transition: opacity 200ms; }
      .toast-manager-component .toast-item .toast-dismiss:hover {
        background-color: #AA0000; }
    .toast-manager-component .toast-item.toast-info .toast-icon {
      background-color: #0f6674; }
    .toast-manager-component .toast-item.toast-success .toast-icon {
      background-color: #00AA00; }
    .toast-manager-component .toast-item.toast-error .toast-icon {
      background-color: #CC0000; }
    .toast-manager-component .toast-item:hover .toast-dismiss {
      opacity: 1; }
@keyframes toast-animation {
  0% {
    margin-left: 999px;
    opacity: 0; }
  5% {
    margin-left: 0;
    opacity: 1; }
  95% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*# sourceMappingURL=ToastManagerComponent.css.map */

.estate-report-table-component .table-filter {
  margin-top: 20px;
  margin-bottom: 20px; }
  .estate-report-table-component .table-filter label {
    display: block;
    width: 100%; }

/*# sourceMappingURL=EstateReportTableComponent.css.map */

.export-container {
  position: relative; }
  .export-container .export-options-container {
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
    background: #FFF;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    padding: 10px;
    z-index: 999;
    margin: 0 10px;
    text-align: center; }
    .export-container .export-options-container .close {
      width: 25px;
      height: 25px;
      cursor: pointer;
      border-radius: 5px;
      position: absolute;
      top: 0;
      right: 0;
      font-size: small;
      margin: 10px;
      display: flex;
      align-items: center;
      text-align: center; }
      .export-container .export-options-container .close .text {
        display: block;
        width: 100%;
        text-align: center; }
      .export-container .export-options-container .close:hover {
        background-color: #EFEFEF; }
    .export-container .export-options-container .export-title {
      font-size: large;
      font-weight: bold;
      display: block; }
    .export-container .export-options-container label {
      display: block; }
    .export-container .export-options-container .react-datepicker-wrapper {
      width: 100%; }
      .export-container .export-options-container .react-datepicker-wrapper input {
        text-align: center; }

/*# sourceMappingURL=ExportDataWidgetComponent.css.map */

.site-selection-component .site-list {
  margin-top: 20px; }
  .site-selection-component .site-list .site-item {
    padding: 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #CCC;
    cursor: pointer; }
    .site-selection-component .site-list .site-item:hover {
      background-color: #EFEFEF; }
    .site-selection-component .site-list .site-item .checkmark {
      width: 25px;
      height: 25px;
      display: inline-block; }
      .site-selection-component .site-list .site-item .checkmark img {
        width: 100%;
        height: auto; }
    .site-selection-component .site-list .site-item .content-area {
      flex: 1 1;
      margin-left: 10px; }
      .site-selection-component .site-list .site-item .content-area .item-title {
        font-size: large;
        font-weight: bold;
        display: block; }
      .site-selection-component .site-list .site-item .content-area .item-address {
        color: #333; }

/*# sourceMappingURL=SiteSelectionComponent.css.map */

.audit-question-list-component {
  padding-bottom: 80px; }
  .audit-question-list-component .bottom-button {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.8);
    -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
    border-top: 1px solid #CCC;
    padding: 10px 0; }
  .audit-question-list-component .question-list {
    margin-top: 20px; }
    .audit-question-list-component .question-list .list-heading {
      padding: 10px 20px;
      border-bottom: 1px solid #CCC;
      cursor: default; }
    .audit-question-list-component .question-list .question-title {
      background: #DDD;
      display: flex;
      flex-direction: row;
      align-items: center; }
      .audit-question-list-component .question-list .question-title .question-title-icon {
        width: 25px;
        height: 25px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        margin-left: 10px; }
      .audit-question-list-component .question-list .question-title .question-title-text {
        flex: 1 1;
        padding: 10px;
        font-weight: bold; }
    .audit-question-list-component .question-list .question-item {
      display: flex;
      border-bottom: 1px solid #CCC;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      padding: 10px 20px; }
      .audit-question-list-component .question-list .question-item:hover {
        background: #EFEFEF; }
      .audit-question-list-component .question-list .question-item .checkmark {
        width: 24px;
        height: 24px;
        border: 2px solid #000;
        border-radius: 50%;
        padding: 2px; }
        .audit-question-list-component .question-list .question-item .checkmark .tick {
          width: 16px;
          height: 16px;
          display: block;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center; }
      .audit-question-list-component .question-list .question-item .title {
        flex: 1 1;
        font-weight: bold;
        margin-left: 20px; }
  .audit-question-list-component .signature-box {
    margin: 20px 0; }

/*# sourceMappingURL=AuditQuestionListComponent.css.map */

.audit-finalisation-modal-component .alert {
  margin-top: 10px; }
.audit-finalisation-modal-component .signature {
  margin-top: 10px; }
  .audit-finalisation-modal-component .signature label {
    font-weight: bold; }

/*# sourceMappingURL=AuditFinalisationModalComponent.css.map */

.audit-question-input-component {
  padding-bottom: 40px; }
  .audit-question-input-component .question-title {
    font-size: large;
    margin-top: 20px; }
  .audit-question-input-component .input-area {
    margin-top: 40px; }
    .audit-question-input-component .input-area .input-items .input-item-wrapper:nth-child(1) {
      padding-right: 0; }
    .audit-question-input-component .input-area .input-items .input-item-wrapper:nth-child(2) {
      padding-left: 7px;
      padding-right: 7px; }
    .audit-question-input-component .input-area .input-items .input-item-wrapper:nth-child(3) {
      padding-left: 0; }
  .audit-question-input-component .input-item {
    border: 1px solid #FFF;
    background: #FFF;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer; }
    .audit-question-input-component .input-item.active {
      border-color: #CCC;
      background-color: #F8F8F8; }
    .audit-question-input-component .input-item:hover {
      border-color: #CCC; }
    .audit-question-input-component .input-item button {
      word-wrap: break-word; }
    .audit-question-input-component .input-item .button-small-label {
      font-size: small; }
    .audit-question-input-component .input-item .button-xsmall-label {
      font-size: x-small; }
    .audit-question-input-component .input-item .input-image {
      border-radius: 10px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-color: #EFEFEF;
      position: relative; }
      .audit-question-input-component .input-item .input-image.input-image-icon {
        background-size: 45px 45px; }
      .audit-question-input-component .input-item .input-image .fullscreen-icon {
        width: 30px;
        height: 30px;
        background: rgba(0, 0, 0, 0.4);
        position: absolute;
        bottom: 0;
        right: 0;
        cursor: pointer;
        border-radius: 4px;
        margin: 5px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat; }
        .audit-question-input-component .input-item .input-image .fullscreen-icon:hover {
          background-color: rgba(0, 0, 0, 0.8); }
    .audit-question-input-component .input-item button {
      min-height: 2.4rem;
      margin-top: 10px; }
  .audit-question-input-component .extra-inputs-area {
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: opacity 200ms; }
    .audit-question-input-component .extra-inputs-area.expanded {
      height: auto;
      overflow: auto;
      opacity: 1; }
    .audit-question-input-component .extra-inputs-area label {
      display: block;
      padding: 5px 10px;
      font-weight: bold;
      margin-bottom: 0;
      margin-top: 10px; }
    .audit-question-input-component .extra-inputs-area textarea {
      height: auto;
      min-height: 80px; }
    .audit-question-input-component .extra-inputs-area .photo-thumb-gallery {
      min-height: 80px;
      border: 1px solid #CCC;
      padding: 5px 10px;
      border-radius: 5px;
      margin-bottom: 10px; }
      .audit-question-input-component .extra-inputs-area .photo-thumb-gallery .photo-thumb {
        width: 80px;
        height: 80px;
        border: 1px solid #CCC;
        border-radius: 5px;
        background: #EFEFEF;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        display: inline-block;
        margin-right: 5px;
        margin-top: 5px;
        position: relative; }
        .audit-question-input-component .extra-inputs-area .photo-thumb-gallery .photo-thumb .photo-thumb-delete {
          width: 30px;
          height: 30px;
          position: absolute;
          right: 0;
          top: 0;
          margin: 5px;
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0.4);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          border-radius: 5px;
          transition: background-color 200ms; }
          .audit-question-input-component .extra-inputs-area .photo-thumb-gallery .photo-thumb .photo-thumb-delete:hover {
            background-color: rgba(204, 0, 0, 0.8); }
  .audit-question-input-component .submit-area {
    margin-top: 20px; }

/*# sourceMappingURL=AuditQuestionInputComponent.css.map */

.image-lightbox-component {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  z-index: 1000;
  overflow-y: auto; }
  .image-lightbox-component .lightbox-close {
    width: 40px;
    height: 40px;
    position: fixed;
    top: 0;
    right: 0;
    margin: 20px;
    cursor: pointer;
    z-index: 1001;
    border-radius: 5px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; }
    .image-lightbox-component .lightbox-close:hover {
      background-color: rgba(255, 255, 255, 0.2); }
  .image-lightbox-component .spacer {
    flex: 1 1; }
  .image-lightbox-component .image-container {
    text-align: center; }
  .image-lightbox-component .lightbox-image {
    max-width: 100%;
    height: auto; }
  .image-lightbox-component .lightbox-image-comments {
    display: block;
    padding: 20px;
    color: white;
    border: 1px solid #FFF;
    border-radius: 5px;
    margin: 10px 0;
    font-size: medium;
    text-align: center; }
  .image-lightbox-component .button-area {
    margin-bottom: 20px; }
    .image-lightbox-component .button-area .btn {
      margin-top: 10px; }

/*# sourceMappingURL=ImageLightboxComponent.css.map */

.upload-progress-component {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  z-index: 999; }
  .upload-progress-component .spacer {
    flex: 1 1; }
  .upload-progress-component .progress-modal-dialog {
    width: 100%;
    background: #FFF;
    border-radius: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    text-align: center;
    padding: 20px;
    margin: 40px auto; }
    .upload-progress-component .progress-modal-dialog .progress-modal-dialog-title {
      font-size: large;
      font-weight: bold;
      display: block;
      margin-top: 10px; }
    .upload-progress-component .progress-modal-dialog .progress-modal-dialog-message {
      font-size: medium;
      margin-top: 10px;
      display: block; }
    .upload-progress-component .progress-modal-dialog .progress-modal-dialog-progress {
      margin-top: 10px; }

/*# sourceMappingURL=UploadProgressComponent.css.map */

.audit-submission-complete-component {
  margin-top: 40px; }
  .audit-submission-complete-component .large-success-icon {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: #73e04c;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    margin-top: 20px;
    animation-name: success-appear;
    animation-duration: 600ms;
    animation-iteration-count: 1;
    animation-delay: 300ms; }
  .audit-submission-complete-component .success-title {
    font-size: xx-large;
    font-weight: bold;
    margin-top: 10px; }
  .audit-submission-complete-component .success-message {
    font-size: large;
    margin-top: 10px; }
  .audit-submission-complete-component .button-area {
    margin-top: 20px;
    margin-bottom: 20px; }
@keyframes success-appear {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

/*# sourceMappingURL=AuditSubmissionCompleteComponent.css.map */

.audit-summary-component {
  padding-bottom: 40px; }
  .audit-summary-component .gauge-chart {
    position: relative; }
    .audit-summary-component .gauge-chart .overlay-display {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center; }
      .audit-summary-component .gauge-chart .overlay-display .title {
        font-size: 3.4em;
        font-weight: 800; }
      .audit-summary-component .gauge-chart .overlay-display .subtitle {
        font-size: large; }
  .audit-summary-component .bar-options {
    margin-top: 20px; }
    .audit-summary-component .bar-options label {
      font-weight: bold;
      margin-bottom: 0;
      margin-top: 5px; }
  .audit-summary-component .button-area {
    margin-top: 40px; }

/*# sourceMappingURL=AuditSummaryComponent.css.map */

.cleaning-specification-list-component {
  padding-bottom: 40px; }
  .cleaning-specification-list-component .expandable-list {
    width: 100%;
    display: block; }
    .cleaning-specification-list-component .expandable-list .expandable-item {
      width: 100%;
      display: flex;
      padding: 8px 10px;
      flex-direction: row;
      align-items: center;
      font-size: large;
      cursor: pointer;
      border-bottom: 1px solid #CCC;
      transition: background-color 200ms; }
      .cleaning-specification-list-component .expandable-list .expandable-item:hover {
        background-color: #EFEFEF; }
      .cleaning-specification-list-component .expandable-list .expandable-item .title-text {
        flex: 1 1; }
      .cleaning-specification-list-component .expandable-list .expandable-item .indicator {
        width: 25px;
        height: 25px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transition: transform 200ms; }
      .cleaning-specification-list-component .expandable-list .expandable-item.expandable-title {
        background-color: #AAA;
        font-weight: 800;
        color: white;
        border-bottom: 0;
        margin-top: 10px; }
        .cleaning-specification-list-component .expandable-list .expandable-item.expandable-title:hover {
          background-color: #888; }
        .cleaning-specification-list-component .expandable-list .expandable-item.expandable-title .indicator {
          transform: rotate(0deg); }
        .cleaning-specification-list-component .expandable-list .expandable-item.expandable-title.expanded .indicator {
          transform: rotate(90deg); }

/*# sourceMappingURL=CleaningSpecificationListComponent.css.map */



/*# sourceMappingURL=MediaViewerPDFComponent.css.map */

.media-viewer-audio-component .audio-player {
  width: 100%;
  height: auto;
  border: 1px solid #CCC;
  border-radius: 5px;
  display: block;
  overflow: hidden; }
  .media-viewer-audio-component .audio-player .control-area {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #EFEFEF; }
    .media-viewer-audio-component .audio-player .control-area .spacer {
      flex: 1 1; }
    .media-viewer-audio-component .audio-player .control-area .control-play-pause {
      width: 64px;
      height: 64px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      border: 1px solid #CCC;
      border-radius: 50%;
      cursor: pointer; }
      .media-viewer-audio-component .audio-player .control-area .control-play-pause:hover {
        background-color: #CCC; }
    .media-viewer-audio-component .audio-player .control-area .control-seek {
      width: 48px;
      height: 48px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      border: 1px solid #CCC;
      border-radius: 50%;
      cursor: pointer; }
      .media-viewer-audio-component .audio-player .control-area .control-seek:hover {
        background-color: #CCC; }
  .media-viewer-audio-component .audio-player .seekbar-container {
    width: 100%;
    height: 15px;
    display: block;
    border-top: 1px solid #CCC;
    cursor: pointer;
    position: relative; }
    .media-viewer-audio-component .audio-player .seekbar-container .seekbar {
      height: 15px;
      display: block;
      background-color: #999; }
    .media-viewer-audio-component .audio-player .seekbar-container .time-indicator {
      height: auto;
      font-size: x-small;
      font-weight: bold;
      top: 0;
      bottom: 0;
      position: absolute;
      padding: 0 5px; }
      .media-viewer-audio-component .audio-player .seekbar-container .time-indicator.start-time {
        left: 0; }
      .media-viewer-audio-component .audio-player .seekbar-container .time-indicator.end-time {
        right: 0; }

/*# sourceMappingURL=MediaViewerAudioComponent.css.map */

.cleaning-specification-detail-component {
  padding-bottom: 40px; }
  .cleaning-specification-detail-component .card {
    margin-top: 20px; }

/*# sourceMappingURL=CleaningSpecificationDetailComponent.css.map */

.alert-list-component {
  padding-bottom: 40px; }
  .alert-list-component .alert-list .alert-list-item {
    display: flex;
    border-bottom: 1px solid #CCC;
    cursor: pointer;
    flex-direction: row;
    align-items: center;
    padding: 10px 0; }
    .alert-list-component .alert-list .alert-list-item:hover {
      background: #EFEFEF; }
    .alert-list-component .alert-list .alert-list-item .read-indicator {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #CC0000;
      margin: 0 10px; }
    .alert-list-component .alert-list .alert-list-item .alert-content {
      flex: 1 1;
      display: block; }
      .alert-list-component .alert-list .alert-list-item .alert-content .alert-title {
        font-size: large;
        font-weight: 800;
        display: block; }
      .alert-list-component .alert-list .alert-list-item .alert-content .alert-message {
        display: block; }
    .alert-list-component .alert-list .alert-list-item .indicator {
      width: 20px;
      height: 20px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      margin: 0 10px; }
    .alert-list-component .alert-list .alert-list-item.read .read-indicator {
      background: rgba(0, 0, 0, 0); }
    .alert-list-component .alert-list .alert-list-item.read .alert-content .alert-title {
      font-weight: normal; }
  .alert-list-component .alert-list .empty-message {
    display: block;
    padding: 10px;
    text-align: center;
    font-size: large;
    font-style: italic; }

/*# sourceMappingURL=AlertListComponent.css.map */

.alert-detail-component {
  padding-bottom: 40px; }
  .alert-detail-component .alert-title {
    font-size: xx-large;
    font-weight: bold;
    display: block; }
  .alert-detail-component .alert-date {
    font-size: medium;
    font-style: italic;
    display: block;
    margin-top: 5px; }
  .alert-detail-component .alert-message {
    font-size: medium;
    margin-top: 15px; }
  .alert-detail-component .alert-image {
    background-color: #EFEFEF;
    border: 1px solid #CCC;
    border-radius: 10px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 10px;
    cursor: pointer; }
  .alert-detail-component .button-area {
    margin-top: 60px; }

/*# sourceMappingURL=AlertDetailComponent.css.map */

.top-bar-component {
  display: flex;
  background-color: #000;
  padding: 10px;
  align-items: center; }
  .top-bar-component .logo {
    display: block;
    width: 150px;
    height: 40px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; }
  .top-bar-component .spacer {
    flex: 1 1; }
  .top-bar-component .actions {
    display: block; }
    .top-bar-component .actions .btn {
      margin-right: 5px; }
    .top-bar-component .actions a {
      color: white; }
      .top-bar-component .actions a:hover {
        color: #EFEFEF; }
      .top-bar-component .actions a.active {
        background-color: #FFF;
        color: #000; }
        .top-bar-component .actions a.active:hover {
          background-color: #EFEFEF; }
  @media print {
    .top-bar-component {
      /* Hide Top Bar from Print layouts */
      display: none; } }

/*# sourceMappingURL=TopBarComponent.css.map */

.site-configuration-editor-details-component {
  padding-bottom: 40px; }
  .site-configuration-editor-details-component .submit-area {
    margin-top: 20px; }
  .site-configuration-editor-details-component .block-label {
    display: block; }
  .site-configuration-editor-details-component .machine-card .card-title {
    margin-top: 0.75rem;
    margin-bottom: 0; }
  .site-configuration-editor-details-component .remove-icon {
    width: 1.4em;
    height: 1.4em;
    background-repeat: no-repeat;
    background-size: 1em 1em;
    background-position: center;
    background-color: #CC0000;
    display: inline-block;
    cursor: pointer;
    border-radius: 50%;
    margin-top: 5px; }
    .site-configuration-editor-details-component .remove-icon:hover {
      background-color: #AA0000; }

/*# sourceMappingURL=SiteConfigurationEditorDetailsComponent.css.map */

.site-configuration-editor-component .nav-tabs {
  margin-top: 20px; }
  .site-configuration-editor-component .nav-tabs li {
    cursor: pointer; }

/*# sourceMappingURL=SiteConfigurationEditorComponent.css.map */

.site-configuration-audit-areas-component .audit-area-container label {
  display: flex;
  padding: 5px;
  border: 1px solid #CCC;
  border-radius: 5px;
  align-items: center;
  cursor: pointer; }
  .site-configuration-audit-areas-component .audit-area-container label:hover {
    background-color: #EFEFEF; }
  .site-configuration-audit-areas-component .audit-area-container label .text {
    flex: 1 1;
    display: block;
    margin-left: 5px;
    font-weight: bold; }
.site-configuration-audit-areas-component .submit-area {
  margin-top: 20px; }

/*# sourceMappingURL=SiteConfigurationAuditAreasComponent.css.map */

.site-configuration-alert-component {
  padding-bottom: 40px; }
  .site-configuration-alert-component .alert-content {
    min-height: 340px; }
  .site-configuration-alert-component .image-preview {
    background-color: #EFEFEF;
    border: 1px solid #CCC;
    border-radius: 5px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 10px; }
  .site-configuration-alert-component .submit-area {
    margin-top: 20px; }

/*# sourceMappingURL=SiteConfigurationAlertComponent.css.map */

.audit-configuration-statistics-component {
  padding-bottom: 40px; }
  .audit-configuration-statistics-component .table-controls {
    margin-top: 20px; }
  .audit-configuration-statistics-component .audit-table {
    margin-top: 10px; }
    .audit-configuration-statistics-component .audit-table .column-small {
      font-size: small; }
    .audit-configuration-statistics-component .audit-table .no-ui {
      display: none; }
    .audit-configuration-statistics-component .audit-table .no-print {
      display: table-cell; }
    .audit-configuration-statistics-component .audit-table img {
      max-width: 100%;
      height: auto; }
    @media print {
      .audit-configuration-statistics-component .audit-table .no-ui {
        display: table-cell; }
      .audit-configuration-statistics-component .audit-table .no-print {
        display: none; } }
  @media print {
    .audit-configuration-statistics-component .ep-table-paginate-container {
      display: none; } }

/*# sourceMappingURL=SiteConfigurationStatisticsComponent.css.map */

.image-gallery-component {
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999; }
  .image-gallery-component .image-display {
    height: 100vh;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; }
  .image-gallery-component .image-thumbnails {
    height: 100vh;
    background: #FFF;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
    overflow-y: auto; }
    .image-gallery-component .image-thumbnails .header-bar {
      width: 100%;
      display: block;
      border-bottom: 1px solid #EFEFEF;
      padding: 5px;
      text-align: right; }
      .image-gallery-component .image-thumbnails .header-bar .close-button {
        width: 40px;
        height: 40px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
        border-radius: 50%;
        background-color: #CC0000;
        display: inline-block; }
        .image-gallery-component .image-thumbnails .header-bar .close-button:hover {
          background-color: #FF0000; }
    .image-gallery-component .image-thumbnails .thumbnail-container {
      position: relative; }
      .image-gallery-component .image-thumbnails .thumbnail-container .thumbnail-item {
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 5px;
        border: 2px solid #EFEFEF;
        padding-top: 100%;
        margin-top: 15px;
        margin-bottom: 15px;
        cursor: pointer; }
        .image-gallery-component .image-thumbnails .thumbnail-container .thumbnail-item.active {
          border-color: #c69500; }

/*# sourceMappingURL=ImageGalleryComponent.css.map */

.waste-schedule-date-upload-modal-component {
  display: block !important;
  background: rgba(255, 255, 255, 0.8); }
  .waste-schedule-date-upload-modal-component label {
    display: block;
    font-weight: bold; }

/*# sourceMappingURL=WasteScheduleCollectionDateModal.css.map */

.site-configuration-waste-schedules-component {
  padding-top: 20px;
  padding-bottom: 40px; }

/*# sourceMappingURL=SiteConfigurationWasteSchedulesComponent.css.map */

.user-management-editor-component {
  padding: 40px; }
  .user-management-editor-component .selection-list {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 10px; }
  .user-management-editor-component .button-space {
    margin-top: 10px; }
  .user-management-editor-component .h3-match-margin {
    margin-top: 20px; }

/*# sourceMappingURL=UserManagementEditorComponent.css.map */

.user-selection-dialog-component {
  display: block;
  background: rgba(255, 255, 255, 0.8); }
  .user-selection-dialog-component .filter-margin {
    margin-bottom: 16px; }

/*# sourceMappingURL=UserSelectionDialogComponent.css.map */

.segmented-table-container {
  width: 100%;
  display: flex; }
  .segmented-table-container .equal-width {
    flex: 1 1;
    overflow-x: scroll; }
  .segmented-table-container .centre-width {
    flex: 2 1;
    overflow-x: scroll; }
  .segmented-table-container table {
    min-width: 100%;
    border-collapse: collapse; }
    .segmented-table-container table thead {
      background: #000;
      color: #FFF; }
      .segmented-table-container table thead th {
        font-weight: bold;
        padding: 5px;
        border: 1px solid #000;
        font-size: small; }
        .segmented-table-container table thead th .text {
          display: block;
          height: 4.2em;
          overflow: hidden; }
    .segmented-table-container table tbody td {
      border: 1px solid #000;
      padding: 5px;
      font-size: small;
      vertical-align: middle; }
      .segmented-table-container table tbody td .text {
        display: block;
        height: 4.2em;
        overflow: hidden; }

.audit-configurator-landing-component {
  padding: 10px 10px 40px 10px; }
  .audit-configurator-landing-component tr.clickable:hover {
    background-color: rgba(0, 0, 0, 0.05); }

/*# sourceMappingURL=AuditConfiguratorLandingComponent.css.map */

.audit-question-editor-component {
  padding-bottom: 40px; }
  .audit-question-editor-component .question-image-preview {
    border: 1px solid #CCC;
    border-radius: 5px;
    background-color: #EFEFEF;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 10px;
    position: relative; }
    .audit-question-editor-component .question-image-preview .remove {
      width: 30px;
      height: 30px;
      background-color: rgba(0, 0, 0, 0.8);
      background-size: 25px 25px;
      background-repeat: no-repeat;
      background-position: center;
      transition: background-color 400ms;
      cursor: pointer;
      display: block;
      position: absolute;
      border-radius: 5px;
      right: 0;
      top: 0;
      margin: 5px; }
      .audit-question-editor-component .question-image-preview .remove:hover {
        background-color: #CC0000; }
  .audit-question-editor-component .submit-area {
    margin-top: 20px; }

/*# sourceMappingURL=AuditQuestionEditorComponent.css.map */

.segmented-table-container {
  width: 100%;
  display: flex; }
  .segmented-table-container .equal-width {
    flex: 1 1;
    overflow-x: scroll; }
  .segmented-table-container .centre-width {
    flex: 2 1;
    overflow-x: scroll; }
  .segmented-table-container table {
    min-width: 100%;
    border-collapse: collapse; }
    .segmented-table-container table thead {
      background: #000;
      color: #FFF; }
      .segmented-table-container table thead th {
        font-weight: bold;
        padding: 5px;
        border: 1px solid #000;
        font-size: small; }
        .segmented-table-container table thead th .text {
          display: block;
          height: 4.2em;
          overflow: hidden; }
    .segmented-table-container table tbody td {
      border: 1px solid #000;
      padding: 5px;
      font-size: small;
      vertical-align: middle; }
      .segmented-table-container table tbody td .text {
        display: block;
        height: 4.2em;
        overflow: hidden; }

.cleaning-specification-configuration-landing-component {
  padding: 10px 10px 40px 10px; }
  .cleaning-specification-configuration-landing-component .badge {
    font-size: small; }
  .cleaning-specification-configuration-landing-component tr.clickable:hover {
    background-color: rgba(0, 0, 0, 0.05); }

/*# sourceMappingURL=CleaningSpecificationConfiguratorComponent.css.map */

.cleaning-specification-editor-component {
  padding-bottom: 40px; }
  .cleaning-specification-editor-component .media-preview {
    border: 1px solid #CCC;
    border-radius: 5px;
    background-color: #EFEFEF;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 10px;
    position: relative;
    margin-top: 10px; }
    .cleaning-specification-editor-component .media-preview .file-content {
      display: flex;
      align-items: center;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      flex-direction: column; }
      .cleaning-specification-editor-component .media-preview .file-content .spacer {
        flex: 1 1; }
      .cleaning-specification-editor-component .media-preview .file-content .preview-icon {
        width: 25px;
        height: 25px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat; }
      .cleaning-specification-editor-component .media-preview .file-content .preview-title {
        width: 100%;
        display: block;
        font-size: small;
        font-weight: bold;
        text-align: center;
        padding: 5px;
        margin-top: 5px; }
    .cleaning-specification-editor-component .media-preview .remove {
      width: 30px;
      height: 30px;
      background-color: rgba(0, 0, 0, 0.8);
      background-size: 25px 25px;
      background-repeat: no-repeat;
      background-position: center;
      transition: background-color 400ms;
      cursor: pointer;
      display: block;
      position: absolute;
      border-radius: 5px;
      right: 0;
      top: 0;
      margin: 5px; }
      .cleaning-specification-editor-component .media-preview .remove:hover {
        background-color: #CC0000; }
  .cleaning-specification-editor-component .submit-area {
    margin-top: 20px; }
  .cleaning-specification-editor-component .empty-message {
    font-size: medium;
    font-style: italic;
    padding-top: 10px;
    padding-bottom: 10px; }

/*# sourceMappingURL=CleaningSpecificationEditorComponent.css.map */

.settings-landing-component {
  padding-bottom: 40px; }
  .settings-landing-component .actions-area {
    margin-top: 10px; }

/*# sourceMappingURL=SettingsLandingComponent.css.map */

.settings-home-screen-editor-component {
  padding-bottom: 40px; }
  .settings-home-screen-editor-component h4 {
    margin-top: 20px; }
  .settings-home-screen-editor-component .submit-area {
    margin-top: 20px; }

/*# sourceMappingURL=SettingsHomeScreenEditorComponent.css.map */

.settings-site-area-editor-component {
  padding-bottom: 40px; }
  .settings-site-area-editor-component .image-preview {
    border: 1px solid #CCC;
    border-radius: 5px;
    background-color: #EFEFEF;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 10px; }
  .settings-site-area-editor-component .submit-area {
    margin-top: 20px; }

/*# sourceMappingURL=SettingsSiteAreaEditorComponent.css.map */

.segmented-table-container {
  width: 100%;
  display: flex; }
  .segmented-table-container .equal-width {
    flex: 1 1;
    overflow-x: scroll; }
  .segmented-table-container .centre-width {
    flex: 2 1;
    overflow-x: scroll; }
  .segmented-table-container table {
    min-width: 100%;
    border-collapse: collapse; }
    .segmented-table-container table thead {
      background: #000;
      color: #FFF; }
      .segmented-table-container table thead th {
        font-weight: bold;
        padding: 5px;
        border: 1px solid #000;
        font-size: small; }
        .segmented-table-container table thead th .text {
          display: block;
          height: 4.2em;
          overflow: hidden; }
    .segmented-table-container table tbody td {
      border: 1px solid #000;
      padding: 5px;
      font-size: small;
      vertical-align: middle; }
      .segmented-table-container table tbody td .text {
        display: block;
        height: 4.2em;
        overflow: hidden; }

.contractor-hours-landing-component {
  padding: 10px 10px 40px 10px; }

/*# sourceMappingURL=ContractorHoursLandingComponent.css.map */

.dashboard-home-component {
  padding-bottom: 40px; }

/*# sourceMappingURL=DashboardHomeComponent.css.map */

.segmented-table-container {
  width: 100%;
  display: flex; }
  .segmented-table-container .equal-width {
    flex: 1 1;
    overflow-x: scroll; }
  .segmented-table-container .centre-width {
    flex: 2 1;
    overflow-x: scroll; }
  .segmented-table-container table {
    min-width: 100%;
    border-collapse: collapse; }
    .segmented-table-container table thead {
      background: #000;
      color: #FFF; }
      .segmented-table-container table thead th {
        font-weight: bold;
        padding: 5px;
        border: 1px solid #000;
        font-size: small; }
        .segmented-table-container table thead th .text {
          display: block;
          height: 4.2em;
          overflow: hidden; }
    .segmented-table-container table tbody td {
      border: 1px solid #000;
      padding: 5px;
      font-size: small;
      vertical-align: middle; }
      .segmented-table-container table tbody td .text {
        display: block;
        height: 4.2em;
        overflow: hidden; }

.site-configurator-landing-component {
  padding: 10px 10px 40px 10px; }
  .site-configurator-landing-component .site-area-header:hover {
    cursor: pointer;
    background-color: #333; }
  .site-configurator-landing-component tr.clickable:hover {
    background-color: rgba(0, 0, 0, 0.05); }

/*# sourceMappingURL=SiteConfiguratorLandingComponent.css.map */

.forgotten-password-component {
  padding-top: 40px;
  padding-bottom: 40px; }
  .forgotten-password-component label {
    font-weight: bold;
    margin: 10px 0 0 0; }
  .forgotten-password-component .button-space {
    margin-top: 40px; }

/*# sourceMappingURL=RequestForgottenPasswordComponent.css.map */

.contractor-reporting-component {
  padding-bottom: 40px; }
  .contractor-reporting-component .loading-containable {
    position: relative; }
  .contractor-reporting-component .small-table {
    font-size: small !important; }
    .contractor-reporting-component .small-table .btn {
      font-size: small !important; }
  .contractor-reporting-component .chart-container {
    min-height: 300px;
    max-height: 400px; }
  .contractor-reporting-component .table-container {
    overflow: auto;
    padding-left: 0;
    padding-right: 0; }
    .contractor-reporting-component .table-container table {
      max-width: 100% !important; }
  .contractor-reporting-component .column-bold {
    font-weight: bold !important; }
  .contractor-reporting-component .column-green {
    background-color: #96fca2; }
  .contractor-reporting-component .column-orange {
    background-color: #f9bb6b; }
  .contractor-reporting-component .column-red {
    background-color: #f96b6b; }

/*# sourceMappingURL=ContractorReportingComponent.css.map */

.cleaning-specification-list-component {
  padding-bottom: 40px; }
  .cleaning-specification-list-component .text-small {
    font-size: small; }
  .cleaning-specification-list-component .expandable-list {
    width: 100%;
    display: block; }
    .cleaning-specification-list-component .expandable-list .expandable-item {
      width: 100%;
      display: flex;
      padding: 8px 10px;
      flex-direction: row;
      align-items: center;
      font-size: large;
      cursor: pointer;
      border-bottom: 1px solid #CCC;
      transition: background-color 200ms; }
      .cleaning-specification-list-component .expandable-list .expandable-item:hover {
        background-color: #EFEFEF; }
      .cleaning-specification-list-component .expandable-list .expandable-item .title-text {
        flex: 1 1; }
      .cleaning-specification-list-component .expandable-list .expandable-item .indicator {
        width: 25px;
        height: 25px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transition: transform 200ms; }
      .cleaning-specification-list-component .expandable-list .expandable-item.expandable-title {
        background-color: #AAA;
        font-weight: 800;
        color: white;
        border-bottom: 0;
        margin-top: 10px; }
        .cleaning-specification-list-component .expandable-list .expandable-item.expandable-title:hover {
          background-color: #888; }
        .cleaning-specification-list-component .expandable-list .expandable-item.expandable-title .indicator {
          transform: rotate(0deg); }
        .cleaning-specification-list-component .expandable-list .expandable-item.expandable-title.expanded .indicator {
          transform: rotate(90deg); }

/*# sourceMappingURL=WasteProcedureListComponent.css.map */

.waste-procedure-landing-component {
  padding-bottom: 40px; }

/*# sourceMappingURL=WasteProcedureLandingComponent.css.map */

.waste-schedule-upload-modal-component {
  display: block !important;
  background: rgba(255, 255, 255, 0.8); }
  .waste-schedule-upload-modal-component .input-hide {
    width: 1px;
    height: 1px;
    overflow: hidden;
    position: absolute;
    left: -999px;
    top: -999px; }

/*# sourceMappingURL=WasteScheduleUploadModalComponent.css.map */

.waste-procedure-editor-component {
  padding-bottom: 40px; }
  .waste-procedure-editor-component .media-preview {
    border: 1px solid #CCC;
    border-radius: 5px;
    background-color: #EFEFEF;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 10px;
    position: relative;
    margin-top: 10px; }
    .waste-procedure-editor-component .media-preview .file-content {
      display: flex;
      align-items: center;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      flex-direction: column; }
      .waste-procedure-editor-component .media-preview .file-content .spacer {
        flex: 1 1; }
      .waste-procedure-editor-component .media-preview .file-content .preview-icon {
        width: 25px;
        height: 25px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat; }
      .waste-procedure-editor-component .media-preview .file-content .preview-title {
        width: 100%;
        display: block;
        font-size: small;
        font-weight: bold;
        text-align: center;
        padding: 5px;
        margin-top: 5px; }
    .waste-procedure-editor-component .media-preview .remove {
      width: 30px;
      height: 30px;
      background-color: rgba(0, 0, 0, 0.8);
      background-size: 25px 25px;
      background-repeat: no-repeat;
      background-position: center;
      transition: background-color 400ms;
      cursor: pointer;
      display: block;
      position: absolute;
      border-radius: 5px;
      right: 0;
      top: 0;
      margin: 5px; }
      .waste-procedure-editor-component .media-preview .remove:hover {
        background-color: #CC0000; }
  .waste-procedure-editor-component .submit-area {
    margin-top: 20px; }
  .waste-procedure-editor-component .empty-message {
    font-size: medium;
    font-style: italic;
    padding-top: 10px;
    padding-bottom: 10px; }

/*# sourceMappingURL=WasteProcedureEditorComponent.css.map */

